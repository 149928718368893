<template>
    <div class="footer">
        <div class="footer__top">
            <div class="footer__top-contacts">
                <a href="https://api.whatsapp.com/send?phone=77761595595" target="_blank" class="footer__top-contacts-item">
                    <div class="footer__top-contacts-item-icon">
                        <img src="../assets/images/icons/footer-whatsapp-icon.svg" alt="whatsapp">
                    </div>
                    <div class="footer__top-contacts-item-text">
                        <div class="footer__top-contacts-item-text-title">+7 776 1 595 595</div>
                        <div class="footer__top-contacts-item-text-subtitle">WHATSAPP</div>
                    </div>
                </a>

                <a href="tel:595" target="_blank" class="footer__top-contacts-item">
                    <div class="footer__top-contacts-item-icon">
                        <img src="../assets/images/icons/footer-phone-icon.svg" alt="phone">
                    </div>
                    <div class="footer__top-contacts-item-text">
                        <div class="footer__top-contacts-item-text-title">595</div>
                        <div class="footer__top-contacts-item-text-subtitle">{{ $t('footer.free-call') }}</div>
                    </div>
                </a>
            </div>
            <div class="footer__top-social mobile-hide">
                <a href="https://www.instagram.com/bankffin.kz" target="_blank" class="footer__top-social-item">
                    <img src="../assets/images/icons/footer-instagram-icon.svg" alt="instagram">
                </a>
                <a href="https://www.facebook.com/bankffin.kz" target="_blank" class="footer__top-social-item">
                    <img src="../assets/images/icons/footer-facebook-icon.svg" alt="facebook">
                </a>
                <a href="https://vt.tiktok.com/ZSdGVVBCU" target="_blank" class="footer__top-social-item">
                    <img src="../assets/images/icons/footer-tiktok-icon.svg" alt="tiktok">
                </a>
            </div>
        </div>
        <div class="footer__middle mobile-hide">
            <div class="footer__middle-nav">
                <div class="footer__middle-nav-item">
                    <div class="footer__middle-nav-item-title"> {{ $t('nav.private-clients') }} </div>
                    <a href="https://bankffin.kz/ipoteka/">{{ $t('nav.digital-mortgage') }}</a>
                    <a href="https://auto.bankffin.kz">{{ $t('nav.digital-car-loan') }}</a>
                    <a href="https://bankffin.kz/ru/cards">{{ $t('nav.cards') }}</a>
                    <a href="https://bankffin.kz/ru/loans">{{ $t('nav.loans') }}</a>
                    <a href="https://bankffin.kz/ru/deposits/physical">{{ $t('nav.deposits') }}</a>
                    <a href="https://bankffin.kz/ru/transfers">{{ $t('nav.transfers') }}</a>
                    <a href="https://bankffin.kz/ru/restructuring">{{ $t('nav.restructuring') }}</a>
                    <a href="https://bankffin.kz/ru/safes">{{ $t('nav.safes') }}</a>
                    <a href="https://bankffin.kz/ru/deposits/physical/freedom-first">{{ $t('nav.freedom-first') }}</a>
                    <a href="https://bankffin.kz/docs/Bankruptcy.pdf">{{ $t('nav.bankruptcy') }}</a>
                </div>
                <div class="footer__middle-nav-item">
                    <div class="footer__middle-nav-item-title">{{ $t('nav.business') }}</div>
                    <a href="https://bankffin.kz/ru/onboarding">{{ $t('nav.onboarding') }}</a>
                    <a href="https://bankffin.kz/ru/open-account">{{ $t('nav.open-account') }}</a>
                    <a href="https://bankffin.kz/ru/guarantees">{{ $t('nav.guarantees') }}</a>
                    <a href="https://overdraft.bankffin.kz">{{ $t('nav.freedom-box') }}</a>
                    <a href="https://bankffin.kz/ru/deposits/legal">{{ $t('nav.deposits') }}</a>
                    <a href="https://bankffin.kz/ru/unlimited">{{ $t('nav.unlimited') }}</a>
                    <a href="https://bankffin.kz/ru/e-commerce">{{ $t('nav.e-commerce') }}</a>
                    <a href="https://bankffin.kz/ru/nova-24">{{ $t('nav.nova') }}</a>
                </div>
                <div class="footer__middle-nav-item">
                    <div class="footer__middle-nav-item-title">{{ $t('nav.about-bank') }}</div>
                    <a href="https://bankffin.kz/ru/contacts">{{ $t('nav.contacts') }}</a>
                    <a href="https://bankffin.kz/ru/branches">{{ $t('nav.branches') }}</a>
                    <a href="https://bankffin.kz/ru/page/about">{{ $t('nav.about-bank') }}</a>
                    <a href="https://bankffin.kz/ru/exchange-rates">{{ $t('nav.exchange') }}</a>
                    <a href="https://bankffin.kz/ru/documents">{{ $t('nav.documents') }}</a>
                    <a href="https://bankffin.kz/ru/requisites">{{ $t('nav.requisites') }}</a>
                    <a href="https://bankffin.kz/ru/articles">{{ $t('nav.news') }}</a>
                    <a href="https://bankffin.kz/ru/press">{{ $t('nav.press') }}</a>
                    <a href="https://bankffin.kz/ru/priority">{{ $t('nav.priority') }}</a>
                </div>
            </div>
            <div class="footer__middle-app">
                <div class="footer__middle-app-top">
                    <a :href="'https://bankffin.kz/'+i18n().locale" class="footer__middle-app-top-logo">
                        <img src="../assets/images/logo.png" alt="logo">
                    </a>
                    <div class="footer__middle-app-top-address">{{ $t('footer.address') }}</div>
                </div>

                <div class="footer__middle-app-bottom">
                    <div class="footer__middle-app-bottom-title">{{ $t('footer.app') }}</div>
                    <div class="footer__middle-app-bottom-subtitle">{{ $t('footer.app-description') }}</div>
                    <div class="footer__middle-app-bottom-buttons">
                        <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank">
                            <img src="../assets/images/icons/google-play-icon.svg" alt="google-play">
                        </a>

                        <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" target="_blank">
                            <img src="../assets/images/icons/appstore-icon.svg" alt="appstore">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__bottom mobile-hide">
            <div class="footer__bottom-text">© {{ new Date().getFullYear() }}<span v-html="$t('footer.license')"></span></div>
        </div>
        <div class="footer__mobile">
            <div class="footer__mobile-social">
                <a :href="'https://bankffin.kz/'+i18n().locale" class="footer__mobile-logo">
                    <img src="../assets/images/logo.png" alt="logo">
                </a>
                <div class="footer__mobile-social-items">
                    <a href="https://www.instagram.com/bankffin.kz" target="_blank" class="footer__mobile-social-item">
                        <img src="../assets/images/icons/footer-instagram-icon.svg" alt="instagram">
                    </a>
                    <a href="https://www.facebook.com/bankffin.kz" target="_blank" class="footer__mobile-social-item">
                        <img src="../assets/images/icons/footer-facebook-icon.svg" alt="facebook">
                    </a>
                    <a href="https://vt.tiktok.com/ZSdGVVBCU" target="_blank" class="footer__mobile-social-item">
                        <img src="../assets/images/icons/footer-tiktok-icon.svg" alt="tiktok">
                    </a>
                </div>
            </div>
            <div class="footer__mobile-address">{{ $t('footer.address') }}</div>
            <div class="footer__mobile-license">© {{ new Date().getFullYear() }}<span v-html="$t('footer.license')"></span></div>
        </div>
    </div>
</template>

<script>
import i18n from "@/i18n";

export default {
    name: 'MainFooter',
    methods: {
      i18n() {
        return i18n
      },
    }
}
</script>