<template>
  <div class="faq-list__item">
    <h4 :class="{'active':isOpen}" @click="toggleDropdown">
      <span>{{ header }}</span>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0013 19.3811L23.0397 12.3905C23.5647 11.8698 24.4159 11.8698 24.9409 12.3905C25.4659 12.9111 25.4659 13.7552 24.9409 14.2758L16.9521 22.209C16.427 22.7297 15.5756 22.7297 15.0505 22.209L7.06173 14.2758C6.53672 13.7552 6.53672 12.9111 7.06173 12.3905C7.58673 11.8698 8.43794 11.8698 8.96295 12.3905L16.0013 19.3811Z" fill="#747474"/>
      </svg>
    </h4>
    <slide-up-down :active="isOpen" :duration="300">
      <div class="faq-list__item-info" v-html="content"></div>
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'FaqItem',
  components: {
    SlideUpDown
  },
  props: {
    header: String,
    content: String
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>