<template>
    <div id="app">
        <main-header/>
        <router-view></router-view>
        <main-footer/>
    </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue'
import MainFooter from './components/MainFooter.vue'
import i18n from '@/i18n'

export default {
    name: 'App',
    components: {
        MainHeader,
        MainFooter
    },
    computed: {
        locale() {
            return i18n.locale
        }
    },
    watch: {
        locale() {
            this.$router.replace({params: {locale: this.locale}})
        }
    },
}
</script>

<style lang="scss">
#app {
  width: 100%;
}
</style>
