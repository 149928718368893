import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter(to, from, next) {
      next(i18n.locale)
    }
  },
  {
    path: '/:locale',
    component: {
      render(h) {
        return h('router-view');
      },
    },
    beforeEnter(to, from, next) {
      let locale = to.params.locale

      if (i18n.availableLocales.includes(locale)) {
        if (i18n.locale !== locale) {
          i18n.locale = locale
        }

        return next()
      }

      return next({ path: i18n.locale })
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
})

export default router
