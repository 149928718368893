<template>
  <div class="card-modal__layout" @click="toggleModal(false)">
    <div class="card-modal" @click.stop>
      <div class="card-modal__close" @click="toggleModal(false)"></div>
      <div class="card-modal__wrapper">
        <div class="card-modal__title">{{ $t('gift-card-modal.title') }}</div>
        <div class="card-modal__content">
          <img src="@/assets/images/card-img.png" alt="cards">
          <div class="card-modal__right">
            <div class="card-modal__table">
              <div class="card-modal__table-row">
                <img src="@/assets/images/gift-card-modal/img-row-1.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('gift-card-modal.table.row-1.title') }}</h5>
                  <p>{{ $t('gift-card-modal.table.row-1.description') }}</p>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-2.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('gift-card-modal.table.row-2.title') }}</h5>
                  <p>{{ $t('gift-card-modal.table.row-2.description') }}</p>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/gift-card-modal/img-row-3.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('gift-card-modal.table.row-3.title') }}</h5>
                  <div class="card-modal__table-row-items gift-items">
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-1')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-2')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-3')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-4')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-5')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-6')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-7')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-8')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-9')"></div>
                    <div class="card-modal__table-row-item" v-html="$t('gift-card-modal.table.row-3.items.item-10')"></div>
                  </div>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-4.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('gift-card-modal.table.row-4.title') }}</h5>
                  <p>{{ $t('gift-card-modal.table.row-4.description') }}</p>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-5.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('gift-card-modal.table.row-5.title') }}</h5>
                  <p>{{ $t('gift-card-modal.table.row-5.description') }}</p>
                </div>
              </div>
              <div class="card-modal__table-row">
                <img src="@/assets/images/card-modal/img-row-6.png" alt="">
                <div class="card-modal__table-row-text">
                  <h5>{{ $t('gift-card-modal.table.row-6.title') }}</h5>
                  <p>{{ $t('gift-card-modal.table.row-6.description') }}</p>
                </div>
              </div>
            </div>
            <div class="card-modal__info">
              <img src="@/assets/images/icons/info.svg" alt="info">
              <p>{{ $t('card-modal.info.description') }}</p>
              <a :href="'/tariffs/tariffs_gift.xlsx'" download class="card-modal__info-btn-border">
                <div class="card-modal__info-btn">
                  {{ $t('card-modal.info.btn') }}
                </div>
              </a>
              <a :href="'https://bankffin.kz/'+i18n().locale+'/res/mobile-app'" class="get-card__btn-border">
                <div class="get-card__btn">
                  {{ $t('global.get-card') }}
                </div>
              </a>
            </div>
            <img class="card-modal__mobile-img" src="@/assets/images/mobile-card-img.png" alt="cards">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import i18n from "@/i18n";

export default {
    name: 'GiftCardModal',
    data() {
        return {

        }
    },
    methods: {
      toggleModal(data) {
        this.$emit('toggleModal', data);
      },
      i18n() {
        return i18n
      }
    }
}
</script>